import React from "react";

import { SVGProps } from "react";

/**
 * @type ArrowsBadgeIconProps
 */
export type ArrowsBadgeIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const ArrowsBadgeIcon
 */
const ArrowsBadgeIcon = (props: ArrowsBadgeIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" role="img" viewBox="0 0 335 512" xmlns="http://www.w3.org/2000/svg">
            <path d="m155.33 490.07-133.3-133.3a17.141 17.141 0 0 1-5.019-12.122v-310.49a17.141 17.141 0 0 1 17.141-17.141h266.62a17.141 17.141 0 0 1 17.141 17.141v310.49a17.141 17.141 0 0 1-5.019 12.122l-133.32 133.3a17.141 17.141 0 0 1-24.244 0z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="34.027" />
            <path d="m148.1 244.82-53.167 53.167 60.376 50.934m69.032-52.082h-129.43m91.958-202.5 53.167 53.167-60.376 51.04m-69.032-52.082h129.43" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="34.027" />
        </svg>
    );
};

export default ArrowsBadgeIcon;
