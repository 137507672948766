// extracted by mini-css-extract-plugin
export var blockOne = "_404-module--blockOne--vGnI9";
export var blockTwo = "_404-module--blockTwo--zJTOC";
export var blocks = "_404-module--blocks--tlaKf";
export var container = "_404-module--container--GjbTw";
export var featuredImage = "_404-module--featuredImage----Jh1";
export var fourZeroFour = "_404-module--fourZeroFour--7oN4i";
export var header = "_404-module--header---F1Xb";
export var heading = "_404-module--heading--zjxiG";
export var icon = "_404-module--icon--mBh6f";
export var linkButton = "_404-module--linkButton--lhF8K";
export var paragraph = "_404-module--paragraph--ijDHM";