import React from "react";

import { Helmet } from "react-helmet";

import { IGatsbyImageData } from "gatsby-plugin-image";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import ArrowsBadgeIcon from "@js/component/icon/badge/ArrowsBadgeIcon";
import Box from "@js/component/Box";
import FeaturedImage from "@js/component/FeaturedImage";
import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";
import Main from "@js/component/Main";

import * as classNames from "@css/component/template/404.module.scss";


/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        featuredImage: {
            node: {
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                }
            }
        }
    };
};

/**
 * @const FourZeroFour
 */
const FourZeroFour = () => {
    const data: WordpressPageData = useStaticQuery(graphql`
        query {
            wordpressPage(title: { eq: "404" }) {
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 50)
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <Main>
            <Helmet>
                <title>Pagina niet gevonden</title>
            </Helmet>
            {data.wordpressPage && data.wordpressPage.featuredImage && (
                <FeaturedImage
                    className={classNames.featuredImage}
                    gatsbyImageData={data.wordpressPage?.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                >
                    <div className={classNames.container}>
                        <Heading className={classNames.heading} variant="extraLarge">
                            Pagina niet gevonden
                        </Heading>
                    </div>
                </FeaturedImage>
            )}
            <section className={ classNames.fourZeroFour }>
                <div className={ classNames.container }>
                    <Heading className={ classNames.heading } variant="large">Oeps!</Heading>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockOne }>
                            <Box>
                                <Heading className={ classNames.heading } element="h2" variant="medium">
                                    Sorry, we kunnen deze pagina niet (meer) vinden
                                </Heading>
                                <p className={ classNames.paragraph }>
                                    We hebben ons best gedaan, maar het lijkt erop dat deze pagina niet (meer) bestaat
                                    of misschien verhuisd is.
                                </p>
                                <LinkButton className={ classNames.linkButton } to="/">Ga terug naar home</LinkButton>
                            </Box>
                        </div>
                        <div className={ classNames.blockTwo }>
                            <Box>
                                <header className={ classNames.header }>
                                    <ArrowsBadgeIcon className={ classNames.icon } />
                                    <Heading className={ classNames.heading } element="h2">
                                        Vergelijk energielabel-adviseurs.
                                    </Heading>
                                </header>
                                <p className={ classNames.paragraph }>
                                    Vergelijk op prijs, kwaliteit en snelheid op basis van jouw gegevens.
                                </p>
                                <LinkButton className={ classNames.linkButton } to="/vergelijk">
                                    <span>Vergelijk nu</span>
                                    <LongArrowRightIcon className={ classNames.icon } />
                                </LinkButton>
                            </Box>
                        </div>
                    </div>
                </div>
            </section>
        </Main>
    );
};

export default FourZeroFour;
